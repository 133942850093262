import convertToSlug from '~/utility/convertToSlug';
import type { IServiceApi, IServiceApiResponse, IServiceResponse, IService, TServiceType, TServiceStatus, TServicePriceType, TServiceGroupType, IServiceApiSingleResponse, IServiceSingle, IServiceImage } from '~/types/services';

/**
 * Retrieves a paginated list of services.
 *
 * @param {number} areaId - The Area Id to load Services for.
 * @param {number} categoryId - The Category Id to load Services for.
 * @returns {Promise<IServiceApi>} - A promise that resolves to a list of services.
 */
export async function getServices (areaId: number, categoryId: number): Promise<IServiceApi> {
    const appConfig = useAppConfig();
    let response: IServiceApiResponse;

    if (appConfig.useMockData) {
        response = await $fetch('/mock/service/paginate.json');
    } else {
        response = await useAPI<IServiceApiResponse>(`/v1/area/${areaId}/category/${categoryId}/service`, {
            method: 'GET',
        }, true);
    }

    return {
        data: response.data.map((service: IServiceResponse) => formatService(service)),
        meta: response.meta
    };
}

/**
 * Retrieves a paginated list of services.
 *
 * @param {number} accountContextId - The Account Context Id to load Services for.
 * @param {number} categoryId - The Category Id to load Services for.
 * @returns {Promise<IServiceApi>} - A promise that resolves to a list of services.
 */
export async function getServicesByAccountContextId (accountContextId: number, categoryId: number): Promise<IServiceApi> {
    const response = await useAPI<IServiceApiResponse>(`/v1/account-context/${accountContextId}/category/${categoryId}/service`, {
        method: 'GET',
    }, true);

    return {
        data: response.data.map((service: IServiceResponse) => formatService(service)),
        meta: response.meta
    };
}

export async function getService (areaId: number, serviceId: number): Promise<IService> {
    const appConfig = useAppConfig();
    let response: IServiceApiSingleResponse;

    if (appConfig.useMockData) {
        response = await $fetch('/mock/service/paginate.json');
    } else {
        response = await useAPI<IServiceApiSingleResponse>(`/v1/area/${areaId}/service/${serviceId}`, {
            method: 'GET',
        });
    }

    return formatService(response.data);
}

export async function getServiceByAccountContextId (accountContextId: number, serviceId: number): Promise<IService> {
    const response = await useAPI<IServiceApiSingleResponse>(`/v1/account-context/${accountContextId}/service/${serviceId}`, {
        method: 'GET',
    });

    return formatService(response.data);
}

interface IPriceInfo {
    totalCost: number;
    totalDiscount: number;
    totalWithoutDiscount: number;
    code?: {
        success: boolean;
        code: string;
        message: string
    } | {
        type: 'percentage' | 'fixed';
        totalDiscount: number;
        discountPercentage: number | null;
    };
}

export function getPriceByAccountContextId (accountContextId: number, serviceId: number, adults: number, children: number, sessionLength: number, promoCode?: string | null): Promise<IPriceInfo> {
    return getPrice(undefined, accountContextId, serviceId, adults, children, sessionLength, promoCode);
}

export function getPriceByAreaId (areaId: number, serviceId: number, adults: number, children: number, sessionLength: number, promoCode?: string | null): Promise<IPriceInfo> {
    return getPrice(areaId, undefined, serviceId, adults, children, sessionLength, promoCode);
}

async function getPrice (areaId: number | undefined, accountContextId: number | undefined, serviceId: number, adults: number, children: number, sessionLength: number, promoCode?: string | null): Promise<IPriceInfo> {
    const response = await useAPI<{ data: { totalPrice: number; totalDiscount: number; totalWithoutDiscount: number; code: { success: boolean; code: string; message: string } | { type: 'percentage' | 'fixed'; discountPercentage: number; }; }}>('/v1/service/price', {
        method: 'POST',
        body: JSON.stringify({
            areaId,
            contextId: accountContextId,
            serviceId,
            adults,
            children,
            sessionLength,
            code: promoCode,
        }),
    });

    let code;

    if (response.data.code) {
        if ('success' in response.data.code && response.data.code.success === false) {
            code = {
                success: false,
                code: response.data.code.code,
                message: response.data.code.message,
            };
        } else if ('type' in response.data.code) {
            code = {
                type: response.data.code.type,
                totalDiscount: response.data.totalDiscount,
                discountPercentage: response.data.code.discountPercentage || null,
            };
        }
    }

    return {
        totalCost: Number(response.data.totalPrice),
        totalDiscount: Number(response.data.totalDiscount),
        totalWithoutDiscount: Number(response.data.totalWithoutDiscount),
        code,
    } as IPriceInfo;
}

/**
 * Formats a single Service response from the server into a front end object
 * @param {IServiceResponse} service A response object received from the API
 * @returns {IService}
 */
export function formatService (service: IServiceResponse): IService {
    // format images and sort so cover image is first
    let images = (service.images || []).map(image => formatImage(image)).sort(a => a.isCover ? -1 : 1);
    let coverImage = images.find(image => image.isCover) || images[0];

    // if we've loaded a single service based on area & category then we get a single coverImageUrl which we turn into a single image in the same format
    // as when we retrieve a single Service
    if (service.coverImageUrl) {
        images = [
            {
                id: 0,
                isCover: true,
                url: service.coverImageUrl,
            },
        ];
        coverImage = images[0];
    }

    // if we still don't have a cover image then we use a default one and add it to the images array too
    if (!coverImage) {
        images.push({ url: '/layout/service-images/one-webp.webp', isCover: true, id: 0 });
        coverImage = images[0];
    }

    return {
        ...service,
        images,
        coverImage,
        price: Number(service.price),
        payout: Number(service.payout),
        type: service.type as TServiceType,
        status: service.status as TServiceStatus,
        priceType: service.priceType as TServicePriceType,
        groupType: service.groupType as TServiceGroupType,
        modality: {
            id: service.modality.id,
            name: service.modality.name,
        },
        locations: (service.locations || []),
        savedCustomUserLocations: (service.savedCustomUserLocations || []),
        locationType: service.locationType as IService['locationType'],
        maxGuests: service.maxGuests,
        category: service.category
            ? {
                id: service.category.id,
                name: service.category.name,
                slug: convertToSlug(service.category),
            }
            : undefined,
        area: service.area
            ? {
                id: service.area.id,
                name: service.area.name,
                slug: convertToSlug(service.area),
            }
            : undefined,
        slug: convertToSlug(service),
        serviceInfo: (service.serviceInfo || []).map(info => ({
            ...info,
        })).sort((a, b) => a.displayOrder - b.displayOrder),
    };
}

export function formatImage (image: IServiceImage): IServiceImage {
    return {
        id: image.id,
        isCover: image.isCover,
        url: image.url,
    };
}
